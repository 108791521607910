
table.custom-tbl {
	border-collapse: collapse;
	width: 100%;
}
table.custom-tbl thead tr th {
	font-weight: 600;
	font-size: 14px;
	color: #3F2783;
	padding: 10px 0;
	width: 25%;
}
table.custom-tbl>thead>tr>th {
    border-bottom: 1px !important;
}
table.custom-tbl tr th {
    border-color: rgba(63, 39, 131, 0.1) !important;
}
table.custom-tbl td {
    line-height: 28px;
    font-size: 14px;
    color: #3f2783;
}
table.custom-tbl tbody tr td {
    font-size: 14px;
    color: #3f2783;
    padding: 14px 2px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}
table.custom-tbl thead tr th.col-img{
	width: 80px;
	padding: 14px 5px;
}
table.custom-tbl thead tr th.col-img, table.custom-tbl tbody tr td.col-img{
	padding: 14px 8px;
	padding-right: 20px;
}
.pagination {
    padding: 0.5rem;
}

.approve_org__list .nav-tabs {
    border-bottom: 0;
}
.approve_org__list .nav-tabs>li>button {
    line-height: 24px;
    font-weight: 600;
    font-size: 14px;
    height: 48px;
    border-bottom: none;
    position: relative;
	margin-right: 15px;
    border-radius: 20px 20px 0 0;
	cursor: default;
    background-color: #fff;
    border: 2px solid rgba(63, 39, 131, 0.7);
	display: block;
    padding: 10px 15px;
	border-bottom: none !important;
}
.approve_org__list .nav-tabs .nav-link:focus, .approve_org__list .nav-tabs .nav-link:hover {
    border-color: rgba(63, 39, 131, 0.7);
}
.approve_org__list .nav-tabs>li>button.active {
    border: 2px solid rgba(63, 39, 131, 0.7);
	z-index: 1;
}
.approve_org__list .tab-content {
    padding: 0px !important;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: -4px;
	margin-bottom: 100px;
    max-width: 100%;
}
.approve_org__list .tab-content>div {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.approve_org__list .tab-content>div {
    border: 2px solid #3f2783;
    position: relative;
	border: 2px solid #3f2783;
    position: relative;
    min-height: 300px;
}

.approve_org__list .nav-tabs>li>button.active::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 10px;
	left: 0;
	bottom: -6px;
	background: #fff;
}


.edittia {
    text-align: end;
    bottom: -35px;
    position: relative;
}
#org-user-list-tabs-tab-allcases{
	color: #3f2783;
}
#org-user-list-tabs-tab-pending{
	color: #3f2783;
}
#org-user-list-tabs-tab-rejected{
	color: #3f2783;
}
#org-user-list-tabs-tab-rejected.active{
	border: 2px solid #3f2783;
}
#org-user-list-tabs-tab-working{
	color: #3f2783;
}
#org-user-list-tabs-tab-working.active{
    border: 2px solid #3f2783;
}
#org-user-list-tabs-tab-approved{
	color: #3f2783;
}
#org-user-list-tabs-tab-approved.active{
	border: 2px solid #3f2783;
}
#org-user-list-tabs-tabpane-allcases.active.show{
    border: 2px solid #3f2783;
    padding: 15px 0;
	position: relative;
}
#org-user-list-tabs-tabpane-pending.active.show{
    border: 2px solid #3f2783;
    padding: 15px 0;
	position: relative;
}
#org-user-list-tabs-tabpane-rejected.active.show{
    border: 2px solid #3f2783;
    padding: 15px 0;
	position: relative;
}
#org-user-list-tabs-tabpane-working.active.show{
    border: 2px solid #3f2783;
    padding: 15px 0;
	position: relative;
}
#org-user-list-tabs-tabpane-approved.active.show{
	border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}
.panel h4 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
	color: #000;
	/* margin-left: 20px;
	margin-bottom: 30px; */
}
.approve_org__list .form-control, .approve_org__list .form-select {
    max-width: 260px;
}
.col-status.rejected_c {
    color: #3f2783;
}
.col-status.working_c {
    color: #3f2783;
}
.col-status.approved_c {
    color: #3f2783;
}
.approve_org__list .new__note_btn {  
    min-height: 40px;
    display: inline-block;
    text-align: center;
    width: 140px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}
.approve_org__list .new__note_btn img{  
    margin-right: 10px;
}
