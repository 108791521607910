.post__list table.custom-tbl tr .col-action {
    min-width: 80px;
}
.post__list table.custom-tbl thead tr .col-sno,  .post__list table.custom-tbl tbody tr td.col-sno{
    width: 50px;
}
.post__list table.custom-tbl thead tr th {
    width: calc(10% - 130px);
}
.create_video_screen .multi_select_main {
    max-width: 80%;
}
.create_video_screen .multi_select_main .searchWrapper {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
}
.create_video_screen .button_box .next_btn {
    font-size: 18px;
}



.post__list table.custom-tbl tbody tr td {
    /* border-left: 1px solid rgba(63, 39, 131, 0.1);
    border-right: 1px solid rgba(63, 39, 131, 0.1); */
    padding-left: 2px;
    padding-right: 10px;
}
.post__list table.custom-tbl thead tr th {
    padding: 15px 10px 15px 4px;
    /* border-left: 1px solid rgba(63, 39, 131, 0.1);
    border-right: 1px solid rgba(63, 39, 131, 0.1); */
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    color: #fff;
}
.post__list .tableContainer table thead {
    z-index: 1;
    background: rgb(63 39 131);
}